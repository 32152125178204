import convertingRatio from "./convertingRatio";
import sl from "./socialLinks";

export default {
  actualDeliveryFees: "actualDeliveryFees",
  errorMessage: "errorMessage",
  brandSavedSuccess: "brandSavedSuccess",
  syncOrderingMenuSuccess: "syncOrderingMenuSuccess",
  syncOrderingMenuFailure: "syncOrderingMenuFailure",
  addBranchTitle: "addBranchTitle",
  editBranchTitle: "editBranchTitle",
  viewBranchTitle: "viewBranchTitle",
  branchNameLabel: "branchNameLabel",
  branchNamePlaceholder: "branchNamePlaceholder",
  arBranchNameLabel: "arBranchNameLabel",
  arBranchNamePlaceholder: "arBranchNamePlaceholder",
  syncBranchesAndCashier: "syncBranchesAndCashier",
  phoneNumberLabel: "phoneNumberLabel",
  phoneNumberPlaceholder: "phoneNumberPlaceholder",
  integrationIdLabel: "integrationIdLabel",
  branchIdLabel: "branchIdLabel",
  integrationIdPlaceholder: "integrationIdPlaceholder",
  addressLabel: "addressLabel",
  addressPlaceholder: "addressPlaceholder",
  addBranch: "addBranch",
  addressWithoutMapPlaceholder: "addressWithoutMapPlaceholder",
  addressWithoutMapLabel: "addressWithoutMapLabel",
  longitude: "longitude",
  latitude: "latitude",
  enterTheLocationManually: "enterTheLocationManually",
  arAddressWithoutMapPlaceholder: "arAddressWithoutMapPlaceholder",
  arAddressWithoutMapLabel: "arAddressWithoutMapLabel",
  editBranch: "editBranch",
  title: "title",
  subtitle: "subtitle",
  branchesCount: "branchesCount",
  branchSearchPlaceholder: "branchSearchPlaceholder",
  newBranch: "newBranch",
  callCenterToggleTitle: "callCenterToggleTitle",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  markComplete: convertingRatio.markComplete,
  noBranchesAdded: "noBranchesAdded",
  noMatch: "noMatch",
  addBranchSuccess: "addBranchSuccess",
  addBranchZonesSuccess: "addBranchZonesSuccess",
  addBranchZonesFailure: "addBranchZonesFailure",
  addBranchZonesLoading: "addBranchZonesLoading",
  addBranchFailure: "addBranchFailure",
  initializeBranchFailed: "initializeBranchFailed",
  brandSettings: "brandSettings",
  Main: "Main",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  submit: sl.submit,
  callCenterTitle: "callCenterTitle",
  callCenterDesc: "callCenterDesc",
  callCenterSeeCashiers: "callCenterSeeCashiers",
  assignedCashiers: "assignedCashiers",
  show: "show",
  more: "more",
  less: "less",
  addBranchButtonTitle: "addBranchButtonTitle",
  deleteBranch: "deleteBranch",
  deleteBranchDescription: "deleteBranchDescription",
  confirmDelete: "confirmDelete",
  cancel: "cancel",
  government: "government",
  orderingStatus: "orderingStatus",
  enabled: "enabled",
  disabled: "disabled",
  addZone: "addZone",
  addDeliveryZones: "addDeliveryZones",
  zonesAdded: "zonesAdded",
  deliverySystem: "deliverySystem",
  deliverySystemContent: "deliverySystemContent",
  atLeastOneZone: "atLeastOneZone",
  pickupSystem: "pickupSystem",
  pickupSystemContent: "pickupSystemContent",
  editDeliveryZones: "editDeliveryZones",
  district: "district",
  area: "area",
  zone: "zone",
  editZone: "editZone",
  branchInfo: "branchInfo",
  deliveryZone: "deliveryZone",
  deliveryFees: "deliveryFees",
  deliveryTime: "deliveryTime",
  zoneName: "zoneName",
  pound: "pound",
  time: "time",
  apply: "apply",
  checkAll: "checkAll",
  unCheckAll: "unCheckAll",
  addAllDeliveryTimeAndFees: "addAllDeliveryTimeAndFees",
  zones: "zones",
  deleteDistrictZonesDescription: "deleteDistrictZonesDescription",
  deleteDistrictZones: "deleteDistrictZones",
  selectZonesBeforeFillingSelected: "selectZonesBeforeFillingSelected",
  searchForDistrict: "searchForDistrict",
  searchForArea: "searchForArea",
  zonesWithNoArea: "zonesWithNoArea",
  mayContainMultipleZones: "mayContainMultipleZones",
  restaurantHeading: "restaurantHeading",
  restaurantParagraph: "restaurantParagraph",
  koinzDeliveryHeading: "koinzDeliveryHeading",
  koinzDeliveryParagraph: "koinzDeliveryParagraph",
  offlineLoyaltyStatus: "offlineLoyaltyStatus",
  driveThruTooltip: "driveThruTooltip",
};
